import { computed } from 'vue';
import { useStore } from 'vuex';

// types
import type { ComputedRef } from 'vue';
import type { WopsOrderInterface } from '@/classes/order';
import type { WopsGiftInterface } from '../classes/gift';
import type { HumanReadableBoolean } from '@/types/humanReadable';
import { isGiftResource } from '@/helpers/outgoingOrder';
import { OrderStatus } from '@/constants/statuses/orderStatuses';

interface UseOrder {
  item: ComputedRef<WopsOrderInterface | WopsGiftInterface>;
  itemNumber: ComputedRef<string>;
  deliveryMethod: ComputedRef<string>;
  isNewCustomer: ComputedRef<HumanReadableBoolean>;
  userReference: ComputedRef<string>;
  userNo: ComputedRef<string>;
  parcels: ComputedRef<number>;
  oversized: ComputedRef<boolean>;
  shippingMethod: ComputedRef<string>;
  courier: ComputedRef<string>;
  isGiftOrder: ComputedRef<boolean>;
  giftCode: ComputedRef<string | null | undefined>;
  deliveryLabelFailed: ComputedRef<boolean>;
  itemIsPacked: ComputedRef<boolean>;
}

export default function (): UseOrder {
  const store = useStore();
  const item: ComputedRef<WopsOrderInterface> = computed(() => store.state.outgoingOrder.item);
  const itemNumber: ComputedRef<string> = computed(() => `#${item.value?.reference}`);
  const deliveryMethod: ComputedRef<string> = computed(() => item.value?.deliveryMethod ?? 'Standard');
  const isNewCustomer: ComputedRef<HumanReadableBoolean> = computed(() =>
    item.value?.isFirstOrder ? 'Yes' : 'No'
  );
  const userReference: ComputedRef<string> = computed(() => item.value?.user?.reference || '');
  const itemIsPacked: ComputedRef<boolean> = computed(() => item.value?.statusId === OrderStatus.PACKED);
  const userNo: ComputedRef<string> = computed(() => userReference.value?.split('-')[1]);
  const parcels: ComputedRef<number> = computed(() => store.state.outgoingOrder.parcels ?? 1);
  const oversized: ComputedRef<boolean> = computed(() => store.state.outgoingOrder.oversized ?? false);
  const isGiftOrder: ComputedRef<boolean> = computed(() => item.value?.isGift ?? false);
  const giftCode: ComputedRef<string | null | undefined> = computed(() =>
    isGiftResource(item.value) ? item.value.code : item.value?.gift?.code ?? 'N/A'
  );
  const shippingMethod: ComputedRef<string> = computed(
    () => item.value?.shippingManifest?.shippingService?.name ?? ''
  );
  const courier: ComputedRef<string> = computed(
    () => item.value?.shippingManifest?.shippingService?.courier ?? ''
  );

  const deliveryLabelFailed: ComputedRef<boolean> = computed(
    () => store.state.outgoingOrder.deliveryLabelFailed
  );

  return {
    item,
    itemNumber,
    deliveryMethod,
    isNewCustomer,
    userReference,
    userNo,
    parcels,
    oversized,
    shippingMethod,
    courier,
    isGiftOrder,
    giftCode,
    deliveryLabelFailed,
    itemIsPacked,
  };
}
