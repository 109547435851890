import BaseOrderInterface from 'whirli-client/dist/Interfaces/order/Order';
import type { WopsUserInterface } from '@/classes/user';
import ShippingManifest from 'whirli-client/dist/Interfaces/delivery/ShippingManifestInterface';

export interface WopsOrderInterface extends Omit<BaseOrderInterface, 'user'> {
  user?: WopsUserInterface;
  shippingManifest?: ShippingManifest;
  code?: null;
}

export class WopsOrder implements WopsOrderInterface {
  user?: WopsUserInterface;
  shippingManifest?: ShippingManifest;
  code?: null = null;

  constructor(page: WopsOrderInterface) {
    Object.assign(this, page);
  }
}
